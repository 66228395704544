<template>
<div class="login-test">
  <div class="d-flex" id="signin" style="height: 100vh; background-image: url('/img/city.jpg'); flex-wrap: wrap;background-position: center;background-size: cover;">
  <!--  <div class="signin-form rounded-2 shadow-3 align-self-center" style="background:rgb(0 0 0 / 82%)">
      <form @submit.prevent="onSubmit" class="text-white">
        <div class="text-center">
          <img src="img/logo.png" alt="" >
        </div>

        <h3 class="text-center">Sign In</h3>

        <div class="form-group text-start">
          <label>Username:</label>
          <input  type="text"
                  id="email"
                  v-model="username"  class="form-control form-control-lg" />
        </div>

        <div class="form-group text-start">
          <label >Password:</label>
          <input type="password"
                 id="password"
                 v-model="password" class="form-control form-control-lg" />
        </div>
      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-outline-light btn-lg mt-3 btn-block" style="color:weight; border: 2px solid weight;">Sign In</button>
      </div>
      </form>
      
    </div>-->
    
  </div>
<div class="section hpanel rightpan">
	<div class="background-img d-flex">
     <div class="signin-form content-area " >
      <form @submit.prevent="onSubmit" class="text-white">
        <div class="d-flex justify-content-center">
       
        <div class="text-center px-2" style="width:30vh">
          <img src="img/perifereia_attica_logo.png" alt="" class="w-100" >
        </div>

        </div>

        <h3 class="text-center pt-5">Sign In</h3>

        <div class="form-group text-start">
          <label>Username:</label>
          <input  type="text"
                  id="email"
                  v-model="username"  class="form-control form-control-lg" />
        </div>

        <div class="form-group text-start">
          <label >Password:</label>
          <input type="password"
                 id="password"
                 v-model="password" class="form-control form-control-lg" />
        </div>
        <label >Language:</label>
        <b-form-select v-model="selected_languege" class="mb-3" @change="changeLang">
          
          <!-- This slot appears above the options from 'options' prop -->
          <template #first>
            <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
          </template>

          <!-- These options will appear after the ones from 'options' prop -->
          <b-form-select-option value="en">English</b-form-select-option>
          <b-form-select-option value="gr">Ελληνικά</b-form-select-option>
        </b-form-select>

      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-outline-light btn-lg mt-3 btn-block" style="color:weight; border: 2px solid weight;">Sign In</button>
      </div>
      <div class="d-flex justify-content-center pt-4 align-items-center">
      <div class="text-center px-2" style="width:11vh">
          <img src="img/logo.png" alt="" class="w-100">
      </div>
      <div class="text-center px-2" style="width:14vh">
          <img src="img/iadet-logo.png" alt="" class="w-100">
      </div>
      <div class="text-center px-2" style="width:11vh">
          <img src="img/noa-logo.png" alt="" class="w-100">
      </div>
      </div>
      </form>
    <p class="text-center pt-4" style="font-size:10px;">Version 1.1.2</p>
    </div>
	</div>
	
</div>

</div>
</template>

<script>
  //import store from "../../store";
  //import routes from '../router/index.js'
  export default {
    data () {
      return {
        username: '',
        password: '',
        selected_languege:'gr'
      }
    },
    methods: {
      onSubmit () {
        const formData = {
          username: this.username,
          password: this.password,
        }
        //console.log(formData)
        //localStorage.setItem('username', this.username)
        //this.$store.dispatch('login', {username: formData.username, password: formData.password})
          localStorage.setItem('languege', this.selected_languege);

          this.$store.dispatch('login', {username: formData.username, password: formData.password})
          //routes.push({ path: '/dashboard' }
        /*
        if (store.getters.isAuthenticated) {
          console.log('heredashboard')
          this.$router.push('dashboard')
        } else {
          console.log('heresignin')
          console.log(store.getters.isAuthenticated)
          this.$router.push('signin')
        }
        */
      },
      changeLang(){
        this.$store.commit('set', ['languege', this.selected_languege])
        
        //this.$store.commit('set', ['languege', this.selected_languege])
        //localStorage.setItem('languege', this.selected_languege);
      }


    },
    created(){
      this.$store.commit('set', ['languege', 'gr'])

    }
  }
</script>

<style >
.button:hover {
  color: white;
}

  .signin-form {
    width: 600px;
    margin: auto;
    /*border: 1px solid #eee;*/
    padding: 20px;
    /*box-shadow: 0 2px 3px #ccc;*/
  
  }


.hpanel {
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff
}

.rightpan {
	right: -4%;
	width: 40%;
	-webkit-transform: skew(-7deg);
	-moz-transform: skew(8deg);
	-o-transform: skew(8deg);
}
.rightpan .background-img {
	background:rgba(0, 0, 0, 0.9)
}
.background-img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.rightpan .content-area {-webkit-transform: skew(8deg);
	-moz-transform: skew(-8deg);
	-o-transform: skew(-8deg);
  }




body{
  
	line-height: 1.6;
	font-weight: 100;
	font-family: 'Poppins', sans-serif;
	color: #555555;
	overflow-x: hidden;
}

.noscroll {
	overflow: hidden
}
@media only screen and (max-width: 1092px) {
  .rightpan {
		right: 0%;
	width: 100%;
	-webkit-transform: skew(0deg);
	-moz-transform: skew(0deg);
	-o-transform: skew(0deg);
}
.rightpan .content-area {-webkit-transform: skew(0deg);
	-moz-transform: skew(0deg);
	-o-transform: skew(0deg);
  }
.rightpan .background-img {
	background:rgba(0, 0, 0, 0.7)
}

}

</style>
